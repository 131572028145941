import "@babel/polyfill";
import Glide, { Autoplay, Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import ScrollReveal from 'scrollreveal';

import styles from '../css/app.pcss';

import LogoBertinchamps from "../img/logo-bertinchamps.png";

// App main
const main = async() => {
    // Import our CSS
    //const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');

    const fontawesome = await
    import ( /* webpackChunkName: "fontawesome" */ 'font-awesome/css/font-awesome.min.css');
    const glideCss = await
    import ( /* webpackChunkName: "glide" */ '@glidejs/glide/dist/css/glide.core.css');
    const Vue = await
    import ( /* webpackChunkName: "vue" */ 'vue');
    const VueCookies = await
    import ( /* webpackChunkName: "vuecookies" */ 'vue-cookies');
    //const ScrollReveal = await
    //import ( /* webpackChunkName: "scroll-reveal" */ './scroll-reveal.js');

    // Create our vue instance
    const vm = new Vue.default({
        el: "#app",
        components: {
            //'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
        },
        data: {
            openMobile: false,
            ageNotPassed: false,
            agePassed: false,
            ageGate: false,
            cookieConsented: false,
            lang: false,
            page: false,
            redirect: false
        },
        methods: {
            toggleMobile(e) {
                e.preventDefault();
                this.openMobile = !this.openMobile;
            },
            agePass(e) {
                this.ageNotPassed = false;
                VueCookies.set('agePassed', true);
                var href = this.lang == 'fr' ? '/' : '/' + this.lang;
                window.location.href = href;
            },
            ageNoPass(e) {
                this.ageNotPassed = true;
                VueCookies.remove('agePassed');
            },
            cookieConsent(e) {
                this.cookieConsented = true;
                VueCookies.set('cookieConsent', true);
            }
        },
        mounted() {
            VueCookies.config('7d');

            this.lang = this.$el.getAttribute('data-lang');
            this.page = this.$el.getAttribute('data-page');
            this.redirect = this.$el.getAttribute('data-redirect');
            this.ageGate = this.page == 'agegate' ? true : false;

            this.cookieConsented = VueCookies.get('cookieConsent');

            this.agePassed = true; //VueCookies.get('agePassed');
            if (!this.agePassed && !this.ageGate) {
                var href = this.lang == 'fr' ? '/agegate' : '/' + this.lang + '/agegate'
                window.location.href = href;
            }

            if (this.agePassed && this.redirect) {
                window.location.href = this.redirect;
            }
        },
    });

    var footerLogoImage = document.getElementById('site-footer__logo');
    if (footerLogoImage) footerLogoImage.src = LogoBertinchamps;
};
// Execute async function
main().then((value) => {

    for (const el of document.querySelectorAll(".glide")) {
        new Glide(el, {
            type: 'slider',
            autoplay: 3500,
            hoverpause: true,
            animationDuration: 1000
        }).mount({ Controls, Breakpoints, Swipe, Autoplay })
    }

    ScrollReveal().reveal('.reveal', {
        distance: '200px',
        delay: 800,
        scale: 0.85
    });

    ScrollReveal().reveal('.teaser', {
        distance: '400px',
        delay: 200
    });
});